
import Vue from "vue";

export default Vue.extend({
  name: "ExternalLinks",
  props: {},
  data: () => ({
    model: [],
    links: [
      {
        name: "Straßenverkehrssicherheit",
        institution: "Informationen des Bundesministeriums für Digitales und Verkehr zur Straßenverkehrssicherheit",
        link: "https://www.bmvi.de/DE/Themen/Mobilitaet/Strasse/Strassenverkehrssicherheit/strassenverkehrssicherheit.html",
        img_src: require('@/assets/img/external/logo_bmdv_min.svg'),
        lang: "de",
      }, {
        name: "Begleitetes Fahren ab 17 Jahre",
        institution: "Informationen des Bundesministeriums für Digitales und Verkehr zum Begleiteten Fahren ab 17 Jahre",
        link: "https://www.bmvi.de/SharedDocs/DE/Artikel/StV/Strassenverkehr/fahranfaenger-innen-und-begleitetes-fahren-ab-17.html",
        img_src: require('@/assets/img/external/logo_bmdv_min.svg'),
        lang: "de",
      }, {
        name: "Junges Fahren",
        institution: "Informationen des Deutschen Verkehrssicherheitsrats zum Thema \"Junges Fahren\"",
        link: "https://www.dvr.de/themen/junges-fahren",
        img_src: require('@/assets/img/external/logo_dvr_2022_min.svg'),
        lang: "de",
      }, {
        name: "JUNG + SICHER + STARTKLAR",
        institution: "Informationen der Deutschen Verkehrswacht zur Aktion \"Jung+Sicher+Startklar\"",
        link: "https://www.verkehrswacht.de/jungsicherstartklar/",
        img_src: require('@/assets/img/external/logo_deutsche_verkehrswacht_min.svg'),
        lang: "de",
      }, {
        name: "Sicher in meiner Region",
        institution: "Informationen zu gefährlichen Strecken in meiner Region",
        link: "https://www.sicher-in-meiner-region.de/gefahrenstrecken.html",
        img_src: require('@/assets/img/external/logo_simr_min.svg'),
        lang: "de",
      }, {
        name: "Unfallatlas",
        institution: "Informationen zu Verkehrsunfällen und Unfallorten",
        link: "https://unfallatlas.statistikportal.de/",
        img_src: require('@/assets/img/external/logo_stat_aemter_min.svg'),
        lang: "de",
      },{
        name: "Young people",
        institution: "Information of the European Transport Safety Council",
        link: "https://etsc.eu/tag/young-people/",
        img_src: require('@/assets/img/external/logo_etsc_min.svg'),
        lang: "en",
      },

    ]
  }),
  methods: {},
  computed: {},
  watch: {},
});
